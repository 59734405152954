import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { commonUrlBuilder, commonRequest } from "./";
/**
 * Create a uri builder and request function for your specific plugin depending
 * on the rest root and additional parameters.
 *
 * @param options
 * @see urlBuilder
 * @see request
 */

function createRequestFactory(options) {
  var urlBuilder = function urlBuilder(passOptions) {
    return commonUrlBuilder(_objectSpread(_objectSpread({}, passOptions), {}, {
      options: {
        restNamespace: options.restNamespace,
        restNonce: options.restNonce,
        restQuery: options.restQuery,
        restRoot: options.restRoot
      }
    }));
  };

  var request = function request(passOptions) {
    return commonRequest(_objectSpread(_objectSpread({}, passOptions), {}, {
      options: {
        restNamespace: options.restNamespace,
        restNonce: options.restNonce,
        restQuery: options.restQuery,
        restRoot: options.restRoot,
        restRecreateNonceEndpoint: options.restRecreateNonceEndpoint
      }
    }));
  };

  return {
    urlBuilder: urlBuilder,
    request: request
  };
}

export { createRequestFactory };
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
// @see https://github.com/Automattic/wp-calypso/blob/master/packages/i18n-calypso/src/index.js
import interpolate from "interpolate-components";
import * as wpi18n from "@wordpress/i18n";
import wp from "wp";

function sprintf(message) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  return wpi18n.sprintf.apply(wpi18n, [message].concat(args));
}
/**
 * Create multiple functions for a specific plugin so they can be localized.
 *
 * @param slug The slug which you have registered your i18n assets
 * @returns
 */


function createLocalizationFactory(slug) {
  var _ref = window,
      wpi18nLazy = _ref.wpi18nLazy;

  if (wpi18nLazy && wpi18nLazy[slug] && wp && wp.i18n) {
    var _iterator = _createForOfIteratorHelper(wpi18nLazy[slug]),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var localeData = _step.value;
        wp.i18n.setLocaleData(localeData, slug);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  /**
   * Translates and retrieves the singular or plural form based on the supplied number.
   * For arguments sprintf is used, see http://www.diveintojavascript.com/projects/javascript-sprintf for
   * specification and usage.
   *
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#_n
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#sprintf
   */


  function _n(single, plural, count) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
      args[_key2 - 3] = arguments[_key2];
    }

    return sprintf.apply(void 0, [wpi18n._n(single, plural, count, slug)].concat(args));
  }
  /**
   * Translates and retrieves the singular or plural form based on the supplied number, with gettext context.
   * For arguments sprintf is used, see http://www.diveintojavascript.com/projects/javascript-sprintf for
   * specification and usage.
   *
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#_n
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#sprintf
   */


  function _nx(single, plural, context, count) {
    for (var _len3 = arguments.length, args = new Array(_len3 > 4 ? _len3 - 4 : 0), _key3 = 4; _key3 < _len3; _key3++) {
      args[_key3 - 4] = arguments[_key3];
    }

    return sprintf.apply(void 0, [wpi18n._nx(single, plural, count, context, slug)].concat(args));
  }
  /**
   * Retrieve translated string with gettext context.
   * For arguments sprintf is used, see http://www.diveintojavascript.com/projects/javascript-sprintf for
   * specification and usage.
   *
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#_n
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#sprintf
   */


  function _x(single, context) {
    for (var _len4 = arguments.length, args = new Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
      args[_key4 - 2] = arguments[_key4];
    }

    return sprintf.apply(void 0, [wpi18n._x(single, context, slug)].concat(args));
  }
  /**
   * Retrieve the translation of text.
   * For arguments sprintf is used, see http://www.diveintojavascript.com/projects/javascript-sprintf for
   * specification and usage.
   *
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#_n
   * @see https://github.com/WordPress/gutenberg/tree/master/packages/i18n#sprintf
   */


  function __(single) {
    for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
      args[_key5 - 1] = arguments[_key5];
    }

    return sprintf.apply(void 0, [wpi18n.__(single, slug)].concat(args));
  }
  /**
   * This function allows you to interpolate react components to your translations.
   * You have to pass an already translated string as argument! For this you can use the other
   * i18n functions like _n() or __().
   *
   * A translation can look like this: "Hello {{a}}click me{{/a}}." and you have to pass
   * a component with key "a".
   */


  function _i(translation, components) {
    return interpolate({
      mixedString: translation,
      components: components
    });
  }

  return {
    _n: _n,
    _nx: _nx,
    _x: _x,
    __: __,
    _i: _i
  };
}

export { createLocalizationFactory, sprintf };